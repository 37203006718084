

/*
* Colors
*/
$color-primary: #0F1020;
$color-tertiary: #0F1020;

$color-secondary: #3953c5;
$bg-one: #8d81e0;
$site-bg-light: linear-gradient(45deg, #000, #3953c5);

// $color-light: #e0cff0;
$color-light: #fff;
$color-dark: #17142b;
$color-gray: #676768;
$color-border: #eff0f7;
$color-success: #00b374;
$color-warning: #ffbb1c;
$color-danger: #ca3604;
$new-white: #f6f4ff;
$color-shadow: rgba(1, 6, 33, 0.06);
// $bg-one: #130f30;
// $bg-one: #574da0;
// billu

// $bg-one: #230920;

// Light Mode Colors
$color-primary-light: #111;
$color-secondary-light: #5DA9E9;
$color-tertiary-light: #0F1020;
$color-light-bg: #fff;
$color-dark-bg: #17142b;
$color-gray-light: #676768;
$color-heading-light: #fff;

// Dark Mode Colors
$color-primary-dark: #333;
$color-secondary-dark: #3953c5;
$color-tertiary-dark: #222831;
$color-light-dark-bg: #2f3640;
$color-dark-dark-bg: #222831;
$color-gray-dark: #bdbdbd;
$video-shadow-dark: #272626;
$site-bg-dark: linear-gradient(45deg, #000000, #333333);
$color-white-light: #f3f3f3;
$color-nonActiveTabs-dark: #928f8f;
$color-nonActiveTabs-light: #d9d5d5;