.counters {
  &__stats {
    li {
      list-style: none;
      width: 20%;
      padding: 0 15px;
      margin-bottom: 40px;
      @include respond(desktop) {
        width: 33.333%;
      }
      @include respond(laptop) {
        width: 50%;
      }
      @include respond(tablet) {
        width: 100%;
      }
    }

    &-box {
      background-color: #f0eeff;
      width: 100%;
      height: 100%;
      padding: 70px 30px 30px;
      position: relative;
      box-shadow: 5px 5px $color-secondary;
      overflow: hidden;
      transition: all 0.3s;
      &:hover {
        box-shadow: none;
      }

      @include respond(tablet) {
        padding: 30px 30px;
      }

      &__number {
        font-size: 48px;
        color: $color-secondary;
        font-weight: 700;
      }
    }

    &-icon {
      position: absolute;
      top: -15px;
      right: -15px;
      opacity: 0.3;
      svg {
        color: $color-primary;
        opacity: 0.5;
      }
    }
  }
}

.bg-one {
  .counters__stats-box {
    background-color: $color-light;
  }
}
