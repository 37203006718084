/* Ensure the layout container takes full height of the viewport */
.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Main content should expand to fill the space */
.main-content {
    flex: 1;
}