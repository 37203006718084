.chapter__preview2 {
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
  }

  &-container {
    display: flex;
    width: 90vw;

    @include respond(laptop) {
      flex-direction: column;
      display: block;
    }
  }

  .panel {
    background-color: $bg-one;
    background-size: cover;
    background-position: 10000px;
    background-repeat: no-repeat;
    height: 60vh;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    flex: 0.5;
    margin: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px $color-secondary;
    transition: all 0.5s;

    @include respond(laptop) {
      height: 60px;
    }
  }

  .panel img {
    margin: 0;
    opacity: 1;
    writing-mode: vertical-rl;
    text-orientation: upright;

    @include respond(laptop) {
      writing-mode: inherit;
    }
  }

  .panel.active {
    background-position: center;
    flex: 5;
    box-shadow: none;

    @include respond(laptop) {
      height: 70vh;
    }

    @include respond(tablet) {
      height: 60vh;
    }
  }

  .panel.active h3 {
    opacity: 0;
  }

  // book cover preview
  .cover-panel {
    height: 70vh;
    flex: 0.8;

    @include respond(laptop) {
      height: 60px;
    }
  }
}

.bg-one {
  .chapter__preview2 .panel {
    background-color: $color-light;
    border: 5px solid $color-light;
  }
}

@media only screen and (max-width: 768px) {
  .imgStyle {
    transform: rotate(90deg);
    width: 55px;
    height: 37vh;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 769px) {
  .imgStyle {
    width: 10vh;
    padding: 6px 0px;
    height: 60vh;
    border-radius: 8px;
    object-fit: contain;
  }
}