.email-success,
.email-failed,
.email-loading,
.success-msg,
.error-msg {
  text-align: center;
  padding: 10px;
  display: none;
}

.email-loading img {
  width: 20px;
  margin: 0 auto;
}

.email-failed .icon {
  font-size: 20px;
  position: relative;
  top: 5px;
}

.email-failed .icon,
.email-success .icon,
.error-msg .icon,
.success-msg .icon {
  font-size: 20px;
  position: relative;
  top: 2px;
}

.contactv4 {
  &__content {
    li {
      list-style: none;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 5px;
        background-color: $color-primary;
        color: $color-light;
        transition: all 0.5s;
        box-shadow: 3px 3px $color-secondary;
        font-size: 20px;
        margin-right: 20px;

        &:hover {
          background-color: $color-secondary;
          box-shadow: none;
        }

        @media screen and (max-width: 320px) {
          width: 30px;
          height: 30px;
          font-size: 15px;
        }
      }
    }
  }
}

.bg-one {
  .contact-form-box {
    background-color: white;

    input,
    textarea {
      background-color: $bg-one;
    }
  }

  .contact__address {
    background-image: linear-gradient(45deg, #8270fa, #130e1d);
    border: 2px solid $bg-one;
    border-radius: 8px;
  }
}

.selectBox {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 5px;
  height: 45px;
  background-image: linear-gradient(45deg, #fff, #d0caf1);
  color: #777474;
  border: 1px solid #111;
}