// Author 01

// Author 02
.authorv2 {
  &__list {
    h4 {
      margin-bottom: 0px;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding: 10px;
    background-color: $color-primary;
    border-radius: 5px;
    img {
      border-radius: 5px;
    }
  }
}
// Author 03
.authorv3 {
  &__content {
    padding: 85px 60px;
    z-index: 1;
    margin-top: 40px;
    margin-left: -50px;
    background: #FEFEFE;
    border: 1px solid #2F2946;
    border-radius: 8px;
    box-shadow: 5px 5px 5px 5px $color-shadow;

    &--badge {
      color: $color-primary;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 600;
    }

    @include respond(desktop) {
      padding: 40px 30px;
    }
    @include respond(laptop) {
      padding: 30px;
      margin-left: 0;
    }
  }
}

.bg-one  {
  .authorv3__content {
    background-color: $color-light;
  }
}